import { Box, Flex, FlexProps } from '@chakra-ui/react';
import Image, { GatsbyImageFluidProps } from 'gatsby-image';
import React, { FunctionComponent } from 'react';

export const LogoWrapper: FunctionComponent<FlexProps> = (props) => (
  <Flex
    justifyContent={{ base: 'space-between', lg: 'center' }}
    mb="24px"
    px={6}
    {...props}
    alignItems="center"
  />
);

export const Logo: FunctionComponent<GatsbyImageFluidProps> = ({ fluid }) => (
  <Box
    backgroundColor="gray.100"
    boxShadow="0 2px 0 hsla(0, 0%, 100%, .5), inset 0 2px 2px hsla(0, 0%, 0%, 0.2)"
    border="1px solid"
    borderColor="gray.50"
    boxSize={{ base: '64px', lg: '172px' }}
    rounded="full"
    p={2}
  >
    <Image fluid={fluid} />
  </Box>
);
