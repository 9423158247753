import { useTheme, Box } from '@chakra-ui/react';
import get from 'lodash/get';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { IconType, IconBaseProps } from 'react-icons/lib';
import { IconWrapper } from './Icon.style';

export type IconProps = {
  stroke?: string;
  fill?: string;
  IconComponent: IconType;
  iconProps?: IconBaseProps;
} & PropsWithChildren<any>;

export const Icon: FunctionComponent<IconProps> = ({
  stroke,
  fill,
  IconComponent,
  iconProps,
  ...rest
}) => {
  const { colors } = useTheme();
  const strokeColor = stroke ? get(colors, stroke, stroke) : '';
  const fillColor = fill ? get(colors, fill, fill) : '';

  return (
    <IconWrapper stroke={strokeColor} fill={fillColor} {...rest}>
      <IconComponent {...iconProps} />
    </IconWrapper>
  );
};
