import { Box, BoxProps, Text } from '@chakra-ui/react';
import React, { FunctionComponent } from 'react';
import { GrAidOption, GrCatalog, GrHome, GrUser } from 'react-icons/gr';
import { Link } from 'react-scroll';
import { Icon } from '../../common/Icon/Icon';

const links = [
  { label: 'Start', icon: GrHome, scrollName: 'hero' },
  { label: 'Oferta', icon: GrCatalog, scrollName: 'offer' },
  { label: 'Wskazania do terapii', icon: GrAidOption, scrollName: 'indications' },
  { label: 'O mnie', icon: GrUser, scrollName: 'about' },
];

export const Nav: FunctionComponent<BoxProps & { onSelect: () => void }> = ({
  onSelect,
  ...props
}) => (
  <Box {...props}>
    {links.map((link) => (
      <Link
        key={link.label}
        to={link.scrollName}
        containerId="main-section"
        smooth={true}
        duration={700}
        onClick={onSelect}
      >
        <Box
          color="gray.300"
          display="flex"
          justifyContent="space-between"
          px={6}
          py={8}
          cursor="pointer"
          transition="0.1s all ease-in"
          _hover={{ bg: 'gray.600' }}
        >
          <Text fontSize={24}>
            <Icon stroke="gray.500" IconComponent={link.icon} />
          </Text>
          <Text fontWeight={600} fontSize={16}>{link.label}</Text>
        </Box>
      </Link>
    ))}
  </Box>
);
