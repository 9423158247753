import styled from 'styled-components';

export const IconWrapper = styled.span<{ stroke: string; fill: string }>`
  display: inline-block;
  svg,
  svg > * {
    stroke: ${({ stroke }) => stroke};
    fill: ${({ fill }) => fill};
  }
`;
