import { Flex } from '@chakra-ui/react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React from 'react';
import { GrMailOption } from 'react-icons/gr';
import { Icon } from '../common/Icon/Icon';

export const SocialMedia = () => {
  const data = useStaticQuery(graphql`
    query {
      facebookLogo: file(relativePath: { eq: "facebook.png" }) {
        childImageSharp {
          fixed(width: 29) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      instagramLogo: file(relativePath: { eq: "instagram.png" }) {
        childImageSharp {
          fixed(width: 29) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      config: site {
        siteMetadata {
          contact {
            email
            phone
          }
          social {
            facebook
            instagram
          }
        }
      }
    }
  `);
  const { social, contact } = data.config.siteMetadata;
  return (
    <Flex alignItems="center">
      {social.facebook && (
        <a href={social.facebook} style={{ marginRight: '8px' }} target='_blank'>
          <GatsbyImage fixed={data.facebookLogo.childImageSharp.fixed} />
        </a>
      )}
      {social.instagram && (
        <a href={social.instagram} style={{ marginRight: '8px' }} target='_blank'>
          <GatsbyImage fixed={data.instagramLogo.childImageSharp.fixed} />
        </a>
      )}
      {contact.email && (
        <a href={`mailto:${contact.email}`} style={{ marginRight: '8px', fontSize: '31px' }} target='_blank'>
          <Icon IconComponent={GrMailOption} stroke="#8a8f9b" />
        </a>
      )}
    </Flex>
  );
};
