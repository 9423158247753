import { theme as chakraTheme } from '@chakra-ui/react';
import { extendTheme } from "@chakra-ui/react"
import { createBreakpoints } from "@chakra-ui/theme-tools"

const breakpoints = createBreakpoints({
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
})

const overrides = {
breakpoints,
colors: {
  ...chakraTheme.colors,
  // @ts-ignore
  primary: { ...chakraTheme.colors.teal },
},

// @ts-ignore
fonts: {
  ...chakraTheme.fonts,
  body: 'Open Sans',
},
}

export const theme = extendTheme(overrides)
