import { createGlobalStyle, GlobalStyleComponent } from 'styled-components';

export const GlobalStyles: GlobalStyleComponent<any, any> = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
  html,
  body {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  
  body {
    margin: 0 auto;
    position: relative;
  }
  
  #___gatsby {    
    &, & > div {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;
    }
  }
`;
