/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { GlobalStyles } from '../GlobalStyles/GlobalStyles';
import { Sidebar } from '../Sidebar/Sidebar';
import { theme } from '../Theme/theme';
import { LayoutWrapper, MainSection } from './Layout.style';

interface LayoutProps {
  children: any;
}

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <ChakraProvider theme={theme}>
    <GlobalStyles />
    <LayoutWrapper>
      <Sidebar />
      <MainSection>{children}</MainSection>
    </LayoutWrapper>
  </ChakraProvider>
);

export default Layout;
