import { Flex, FlexProps } from '@chakra-ui/react';
import React, { FunctionComponent } from 'react';

export const LayoutWrapper: FunctionComponent<FlexProps> = (props) => (
  <Flex
    flexDirection={{ base: 'column', lg: 'row' }}
    flexGrow={1}
    overflowY="hidden"
    bg="gray.50"
    {...props}
  />
);

export const MainSection: FunctionComponent<FlexProps> = (props) => (
  <Flex as="main" flexGrow={1} flexDir="column" overflowY="auto" id="main-section" {...props} />
);
