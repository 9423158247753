import { Box, Flex } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { GrApps } from 'react-icons/gr';
// @ts-ignore
import logo from '../../images/logo.png';
import { SocialMedia } from '../SocialMedia/SocialMedia';
import { Icon } from '../common/Icon/Icon';
import { Image } from '../common/Image/Image';
import { Nav } from './Nav/Nav';
import { Logo, LogoWrapper } from './Sidebar.style';

export const Sidebar = () => {
  const [isVisible, setIsVisible] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      logoImg: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 172) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Flex
      pt={{ base: 4, md: 8 }}
      pb={{ base: 2 }}
      bg="gray.800"
      minW="xs"
      flexDir="column"
      justifyContent="space-between"
    >
      <Box>
        <LogoWrapper>
          <Logo fluid={data.logoImg.childImageSharp.fluid} />
          <Box
            display={{ base: 'block', lg: 'none' }}
            cursor="pointer"
            fontSize="32px"
            onClick={() => setIsVisible(!isVisible)}
          >
            <Icon stroke="gray.500" IconComponent={GrApps} />
          </Box>
        </LogoWrapper>
        <Nav
          overflow="hidden"
          maxH={{ base: isVisible ? '1000px' : '0px', lg: '10000px' }}
          transition="300ms all ease-out"
          onSelect={() => setIsVisible(false)}
        />
      </Box>
      <Box px={6} color="gray.200" display={{ base: 'none', lg: 'block' }}>
        <SocialMedia />
      </Box>
    </Flex>
  );
};
